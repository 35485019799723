import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import { Checkbox } from "@material-ui/core";
import { Button, ButtonToolbar } from "react-bootstrap";
import UpdateModal from "./UpdateModal";
import DeleteModal from "./DeleteModal";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../../../store/ducks/auth.duck";
import {
  deleteFile,
  deleteFiles,
  downloadFiles,
} from "../../../../../crud/file.crud";
import * as media from "../../../../../crud/media.crud";

const defRow = [
  {
    label: "Date",
    value: "test_date",
    left: true,
  },
  {
    label: "File Name",
    value: "files.file_name",
    left: true,
  },
  {
    label: "File Type",
    value: "files.extension",
    left: true,
  },
  {
    label: "User",
    value: "users.first_name",
    left: true,
  },
];

export default ({ data, meta, setMeta, id, setLoader, fetchData }) => {
  const dispatch = useDispatch();

  const [check, setCheck] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const sortEdit = (item) => {
    setMeta((prev) => {
      return {
        ...prev,
        sort: item.value,
        dir: meta.sort === item.value && meta.dir === "desc" ? "asc" : "desc",
        media_type: "documents",
      };
    });
  };

  const chkAll = () => {
    setCheck(data.length === check.length ? [] : data.map((item) => item.id));
  };

  const handleDelete = () => {
    setLoader(true);
    if (check.length > 1) {
      deleteFiles(id, check)
        .then(() => {
          setDeleteModal(false);
          setLoader(false);
          fetchData();
          setCheck([]);
          dispatch(
            AlertState.actions.alert({
              text: "Files are deleted",
              variant: true,
            })
          );
        })
        .catch(({ response }) => {
          setLoader(false);
          dispatch(
            AlertState.actions.alert({
              text: response.data.error,
              variant: false,
            })
          );
        });
    } else {
      deleteFile(id, check[0])
        .then(() => {
          setDeleteModal(false);
          setLoader(false);
          fetchData();
          setCheck([]);
          dispatch(
            AlertState.actions.alert({
              text: "File is deleted",
              variant: true,
            })
          );
        })
        .catch(({ response }) => {
          setLoader(false);
          dispatch(
            AlertState.actions.alert({
              text: response.data.error,
              variant: false,
            })
          );
        });
    }
  };

  const downloadFile = () => {
    let item = data.find((item) => item.id === check[0]);
    setLoader(true);
    media
      .getImg(item.file.path)
      .then((res) => {
        setLoader(false);
        const link = document.createElement("a");
        link.href = res;
        link.target = "_blank";
        link.setAttribute(
          "download",
          `${item.file.file_name}.${item.file.extension}`
        );
        document.body.appendChild(link);
        if (link.hasAttribute("download")) {
          link.click();
          setTimeout(() => {
            link.remove();
          }, 100);
        } else {
          link.download = `${item.file.file_name}.${item.file.extension}`;
          link.click();
          setTimeout(() => {
            link.remove();
          }, 100);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handlerDownloadFiles = () => {
    setLoader(true);
    downloadFiles(id, check)
      .then((res) => {
        setLoader(false);
        dispatch(
          AlertState.actions.alert({
            text: "Download is started",
            variant: true,
          })
        );
        const { data } = res;
        const link = document.createElement("a");
        const url = URL.createObjectURL(data);
        link.href = url;
        link.download = "Files.zip";
        if (link.hasAttribute("download")) {
          link.click();
          setTimeout(() => {
            link.remove();
          }, 100);
        } else {
          link.download = "Files.zip";
          link.click();
          setTimeout(() => {
            link.remove();
          }, 100);
        }
      })
      .catch(({ response }) => {
        setLoader(false);
        dispatch(
          AlertState.actions.alert({
            text: response.data.error,
            variant: false,
          })
        );
      });
  };

  return (
    <div className="settingtable">
      <UpdateModal
        modal={updateModal}
        setModal={setUpdateModal}
        item={data.find((item) => item.id === check[0])}
        fetchData={fetchData}
        setLoader={setLoader}
        id={id}
      />

      <DeleteModal
        modal={deleteModal}
        setModal={setDeleteModal}
        handleDelete={handleDelete}
      />

      {check.length > 0 && (
        <ButtonToolbar style={{ margin: "25px 0" }}>
          {check.length === 1 && (
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => setUpdateModal(true)}
            >
              Update
            </Button>
          )}
          <Button
            onClick={() => setDeleteModal(true)}
            style={{ marginRight: "10px" }}
          >
            Delete
          </Button>
          <Button
            onClick={check.length === 1 ? downloadFile : handlerDownloadFiles}
          >
            Download
          </Button>
        </ButtonToolbar>
      )}

      <div className="table-container">
        <StyledTable>
          <thead>
            <tr>
              <th>
                {data.length !== 0 && (
                  <Checkbox
                    checked={check.length === data.length && data.length !== 0}
                    onChange={chkAll}
                    color="primary"
                  />
                )}
              </th>
              {defRow.map((item, index) => {
                return (
                  <th
                    key={index}
                    onClick={() => sortEdit(item)}
                    className={`${item.left ? "left-content" : ""}${
                      meta.sort === item.value ? " active" : ""
                    }`}
                  >
                    {item.label}
                    <ArrowDown
                      className={`sort-arrow${
                        meta.sort === item.value ? " active" : ""
                      }${
                        meta.sort === item.value && meta.sort.dir === "desc"
                          ? " reverse"
                          : ""
                      }`}
                    />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <TableRow
                  key={index}
                  item={item}
                  check={check}
                  setCheck={setCheck}
                />
              );
            })}
          </tbody>
        </StyledTable>
      </div>
    </div>
  );
};

const TableRow = ({ item, check, setCheck }) => {
  const editCheck = () => {
    const index = check.findIndex((item2) => item2 === item.id);
    let checkTemp = check;
    if (index === -1) {
      setCheck([...checkTemp, item.id]);
    } else {
      setCheck(check.filter((i) => i !== item.id));
    }
  };

  const getCheck = () => check.findIndex((item2) => item2 === item.id) !== -1;

  return (
    <tr key={item?.id}>
      <td>
        <Checkbox
          checked={getCheck()}
          onChange={() => editCheck()}
          color="primary"
        />
      </td>
      <td className="left-content">
        {moment(item.test_date, "YYYY-MM-DD").format("MM/DD/YYYY")}
      </td>
      <td
        className="left-content"
        style={{
          cursor: "pointer",
          maxWidth: "50px",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {item.file.file_name || "--"}
      </td>
      <td className="left-content">{item.file.extension || "--"}</td>
      <td className="left-content">
        {item.file.user
          ? `${item.file.user.first_name} ${item.file.user.last_name}`
          : "--"}
      </td>
    </tr>
  );
};

const StyledTable = styled.table`
  .left-content {
    text-align: left;
  }

  @media screen and (max-width: 767px) {
    min-width: 750px;

    thead th {
      position: sticky;
      top: 0;
      background-color: #f4f4f4;
      z-index: 2;
      padding: 8px 10px;
      font-size: 14px;
      height: 50px;
    }
    tbody td:first-child,
    thead th:first-child {
      width: 48px;
    }
    tbody td:nth-child(2),
    thead th:nth-child(2) {
      position: sticky;
      left: 0;
    }
    tbody td:first-child,
    tbody td:nth-child(2) {
      z-index: 10;
      background-color: #f9f9f9;
    }
    thead th:first-child,
    thead th:nth-child(2) {
      z-index: 11;
    }
    .th {
      height: 50px;
      font-weight: 500;
      cursor: pointer;
      padding: 8px 10px;
      font-size: 14px;
    }
  }
`;
