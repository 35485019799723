import React from "react";
import styled from "styled-components";
import Table from "./Table";
import Card from "./Card";

export default (props) => {
  const { viewType } = props;

  return (
    <PhotoStyled>
      {viewType === 0 ? <Card {...props} /> : <Table {...props} />}
    </PhotoStyled>
  );
};

const PhotoStyled = styled.div`
  @media screen and (max-width: 757px) {
    .settingtable {
      overflow: hidden !important;
    }
    .table-container {
      max-height: 700px;
      overflow: scroll;
    }

    .pagination {
      margin-bottom: 0;
    }
    .pagination-wrap {
      flex-direction: column;
    }
    .pagination {
      order: 1;
      li {
        font-size: 14px;
      }
    }
    .pagination-lg .page-link {
      padding: 1rem 1.7rem;
      font-size: 16px;
    }
    .pagination-show {
      margin-bottom: 10px;
      margin-left: auto;
      .MuiSelect-selectMenu.MuiOutlinedInput-input {
        padding: 10px 10px 10px 75px !important;
      }
      .MuiSelect-icon {
        left: 5px;
      }
      .formControl {
        width: 100px;
        order: 1;
        margin-left: 10px;
      }
    }
  }
`;
