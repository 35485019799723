import React from "react";
import { TableRow } from "./TableRow";
import { Checkbox } from "@material-ui/core";
import { tableHead } from "./tableHead";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import styled from "styled-components";

export const TasksTable = ({
  data,
  check,
  setCheck,
  sort,
  setSort,
  setCurrentForm,
  setFormViewModal,
}) => {
  const checked = data.length && check.length === data.length;

  const handleCheck = () => {
    if (data.length) {
      if (!checked) {
        setCheck(data.map((elem) => elem.id));
      } else {
        setCheck([]);
      }
    }
  };

  const sortEdit = (item) => {
    item.sort && setSort([item.value, `${sort[1] === "asc" ? "desc" : "asc"}`]);
  };

  return (
    <StyledComponent className="settingtable nowrap">
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox
                color="primary"
                checked={!!checked}
                onChange={handleCheck}
              />
            </th>
            {tableHead.map((item, index) => (
              <th
                key={index}
                onClick={() => {
                  setSort(item.value);
                  sortEdit(item);
                }}
                className={`${item.left ? "left-content" : ""}`}
                style={{ cursor: !item.sort && "default" }}
              >
                {item.label}
                {item.sort && (
                  <ArrowDown
                    className={`sort-arrow
                                            ${
                                              sort[0] === item.value
                                                ? " active"
                                                : ""
                                            }${
                      sort[0] === item.value && sort[1] === "desc"
                        ? " reverse"
                        : ""
                    }
                                            `}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <TableRow
              key={item.id}
              item={item}
              check={check}
              setCheck={setCheck}
              setCurrentForm={setCurrentForm}
              setFormViewModal={setFormViewModal}
            />
          ))}
        </tbody>
      </table>

      {!data.length && (
        <div
          style={{
            margin: "20px auto",
            textAlign: "center",
          }}
        >
          No data available in table
        </div>
      )}
    </StyledComponent>
  );
};
const StyledComponent = styled.div`
  @media screen and (max-width: 767px) {
    overflow: auto;
    width: 100%;
    max-height: 700px;
    thead th {
      position: sticky;
      top: 0;
      background-color: #f4f4f4;
      z-index: 2;
      padding: 8px 10px;
      font-size: 14px;
      height: 50px;
    }
    tbody td:first-child,
    thead th:first-child {
      width: 48px;
    }
    tbody td:nth-child(2),
    thead th:nth-child(2) {
      position: sticky;
      left: 0;
    }
    tbody td:first-child,
    tbody td:nth-child(2) {
      z-index: 10;
      background-color: #f9f9f9;
    }
    thead th:first-child,
    thead th:nth-child(2) {
      z-index: 11;
    }
    .th {
      height: 50px;
      font-weight: 500;
      cursor: pointer;
      padding: 8px 10px;
      font-size: 14px;
    }
  }
`;
