import React from "react";
import { Pagination } from "react-bootstrap";
import styled from "styled-components";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  OutlinedInput,
} from "@material-ui/core";

const PaginationComponent = ({
  page,
  setPage,
  setPagination,
  pagination,
  inputLabel,
  show,
  setShow,
  data = [],
  isOnFront = false,
  paginationSize,
}) => {
  const paginationHandler = (e) => {
    const numPage = Math.floor(data?.length / parseInt(e.target.value));
    let lastPage =
      numPage === data?.length / parseInt(e.target.value)
        ? numPage
        : numPage + 1;
    setShow(e.target.value);

    if (pagination > lastPage) {
      setPagination(1);
    }

    if (!isOnFront) {
      setPage((prev) => {
        return {
          ...prev,
          per_page: e.target.value,
          last_page: lastPage,
        };
      });
    }
  };

  return (
    <StyledPagination>
      <div className="pagination-wrap">
        <Pagination size={paginationSize}>
          <Pagination.First
            onClick={() => setPagination(1)}
            disabled={pagination === 1}
          />
          <Pagination.Prev
            onClick={() => setPagination(pagination - 1)}
            disabled={pagination === 1}
          />
          <Pagination.Item>{pagination}</Pagination.Item>
          <Pagination.Next
            onClick={() => setPagination(pagination + 1)}
            disabled={pagination === page.last_page}
          />
          <Pagination.Last
            onClick={() => setPagination(page.last_page)}
            disabled={pagination === page.last_page}
          />
        </Pagination>
        <div className="pagination-show">
          <FormControl variant="outlined" className="formControl">
            <InputLabel ref={inputLabel}>Show Entries</InputLabel>
            <Select
              value={show}
              input={<OutlinedInput />}
              onChange={(e) => paginationHandler(e)}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="body1" gutterBottom>
            {page &&
              `Showing
                                ${(+pagination - 1) * +page.per_page +
                                  (+page.total ? 1 : 0)} -
                                ${
                                  +pagination * +page.per_page > +page.total
                                    ? +page.total
                                    : +pagination * +page.per_page
                                }
                            of ${+page.total}`}
          </Typography>
        </div>
      </div>
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  .pagination-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
  }

  p {
    margin-left: 10px;
  }

  .MuiSelect-select {
    padding: 7px;
  }

  &-show {
    display: flex;
    align-items: center;
    min-width: 290px;
  }

  .pagination-show {
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiOutlinedInput-input {
      padding: 10px 100px 10px 15px !important;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      background-color: #fff;
      padding: 0 5px;
    }
  }
`;

export default PaginationComponent;
