import React from "react";
import { TableRow } from "./TableRow";
import { Checkbox } from "@material-ui/core";
import { tableHead } from "./tableHead";
import ArrowDown from "@material-ui/icons/ArrowDropDown";

export const Table = ({ data, check, setCheck, sort, setSort }) => {
  const checked = data.length && check.length === data.length;

  const handleCheck = () => {
    if (data.length) {
      if (!checked) {
        setCheck(data.map((elem) => elem.id));
      } else {
        setCheck([]);
      }
    }
  };

  const sortEdit = (item) =>
    setSort([item, `${sort[1] === "asc" ? "desc" : "asc"}`]);

  return (
    <div className="calendar-agenda-table settingtable nowrap">
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleCheck}
              />
            </th>
            {tableHead.map((item, index) => (
              <th
                key={index}
                onClick={() => sortEdit(item)}
                className={`${item.left ? "left-content" : ""}`}
                style={{ cursor: !item.sort && "default" }}
              >
                {item.label}
                {item.sort && (
                  <ArrowDown
                    className={`sort-arrow
                                            ${
                                              sort[0] === item.value
                                                ? " active"
                                                : ""
                                            }${
                      sort[0] === item.value && sort[1] === "desc"
                        ? " reverse"
                        : ""
                    }
                                            `}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <TableRow
              key={item.id}
              item={item}
              check={check}
              setCheck={setCheck}
            />
          ))}
        </tbody>
      </table>

      {!data.length && (
        <div
          style={{
            margin: "20px auto",
            textAlign: "center",
          }}
        >
          No events are planned for this date
        </div>
      )}
    </div>
  );
};
