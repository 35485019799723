import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Chart } from "chart.js";
import { Button, Dropdown } from "react-bootstrap";
// import moment from 'moment'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  // Grid,
  OutlinedInput,
} from "@material-ui/core";
import ModalAddMetrics from "../MetricsTabs/ModalAddMetrics";
import ModalAddGoals from "../MetricsTabs/ModalAddGoals";
import ProtocolModal from "../OverviewTable/ProtocolModal";
import Loader from "../../../components/Loader";
import DatePicker from "../../../components/DatePicker";

export default (props) => {
  const ref = useRef();
  const [color, setColor] = useState(["54, 105, 255, ", "255, 162, 85, "]);

  const getColor = () => {
    const randColor = () =>
      `${Math.floor(Math.random() * 255)},${Math.floor(
        Math.random() * 255
      )},${Math.floor(Math.random() * 255)},`;
    let rand = [...color];
    data.datasets.forEach(() => rand.push(randColor()));
    setColor(rand);
  };

  const datasets = {
    fill: true,
    lineTension: 0.1,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 2,
    pointRadius: 5,
    pointHitRadius: 10,
  };

  // const searchResult = (index, arr, type) => {
  //     let value = null
  //     let touch = true
  //     let last = arr.findIndex((item, i, arr) => {
  //         return index < i && !!item[type]
  //     })

  //     for (let i = index; i > 0; i--) {
  //         if (!!arr[i][type] && touch) {
  //             value = arr[i][type]
  //             touch = false
  //         }
  //     }

  //     return last === -1
  //         ? null
  //         : value
  // }

  const data = {
    labels:
      props.data && props.data.metrics
        ? props.data.metrics.map((item) => item.date)
        : [],
    datasets: [
      {
        ...datasets,
        label: props.data.label || "",
        backgroundColor: `rgba(${color[0]}0.4)`,
        borderColor: `rgba(${color[0]}1)`,
        pointBorderColor: `rgba(${color[0]}1)`,
        data:
          props.data &&
          props.data.metrics &&
          props.data.metrics.map((item, index, arr) => {
            return !!item.result || item.result === 0 ? item.result : null;
          }),
      },
      {
        ...datasets,
        label: props.data.goal_label || "",
        backgroundColor: `rgba(${color[1]}0.4)`,
        borderColor: `rgba(${color[1]}1)`,
        pointBorderColor: `rgba(${color[1]}1)`,
        data:
          props.data &&
          props.data.metrics &&
          props.data.metrics.map((item, index, arr) => {
            return !!item.goal || item.goal === 0 ? item.goal : null;
          }),
      },
    ],
  };

  useEffect(() => {
    getColor();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const chart = new Chart(ref.current, {
      data,
      type: "line",
      options: {
        spanGaps: true,
        maintainAspectRatio: false,
        legend: {
          position: "top",
          align: "end",
          labels: {
            align: "end",
          },
        },
        aspectRatio: 2.6,
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                min:
                  Math.min.apply(
                    null,
                    props.data &&
                      props.data.metrics &&
                      props.data.metrics.map((item) => {
                        return Math.min.apply(
                          null,
                          [
                            parseFloat(item.result),
                            parseFloat(item.goal),
                          ].filter((item) => item === 0 || !!item)
                        );
                      })
                  ) - 10,
                max:
                  Math.max.apply(
                    null,
                    props.data &&
                      props.data.metrics &&
                      props.data.metrics.map((item) => {
                        return Math.max.apply(
                          null,
                          [
                            parseFloat(item.result),
                            parseFloat(item.goal),
                          ].filter((item) => item === 0 || !!item)
                        );
                      })
                  ) + 10,
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
        animation: false,
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "nearest",
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: "#1e1e2d",
          titleFontColor: "#ffffff",
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0,
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data, props.data]);

  const onChange = (date, index) => {
    let arr = [...props.date];
    arr[index] = date;
    props.setDate(arr);
  };

  const [addSkinfold, setAddSkinfold] = useState(false);
  const [bodyFat, setBodyFat] = useState(false);

  useEffect(() => {
    if (props.dataMetric && props.dataMetric.metric) {
      props.setType(props.dataMetric.metric);
    } else if (props.dataMetric && props.dataMetric.childs) {
      props.setType(props.dataMetric.childs[0].metric);
    }
    // eslint-disable-next-line
  }, [props.dataMetric]);

  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel, props.dataMetric, props.typeOutputMetric]);

  const getTitle = (data) => {
    if (data && data.childs) {
      const child = data.childs.find((item) => item.metric === props.type);
      return child ? child.label : "";
    }
    return data ? data.title : "";
  };

  return (
    <StyledDiagram>
      {props.protocolLogout}
      <Loader visible={props.loaderProtocol} />

      <ModalAddMetrics
        id={props.id}
        metric={props.type}
        modal={addSkinfold}
        setModal={setAddSkinfold}
        label={getTitle(props.dataMetric)}
        loadData={props.loadData}
        category={props.dataMetric && props.dataMetric.category}
      />

      <ModalAddGoals
        id={props.id}
        metric={props.type}
        modal={bodyFat}
        setModal={setBodyFat}
        label={getTitle(props.dataMetric)}
        loadData={props.loadData}
      />

      <ProtocolModal
        modal={props.protocol}
        setModal={props.setProtocol}
        data={props.protocolData}
        label={getTitle(props.dataMetric)}
      />

      <div className="nav-menu">
        <div className="select">
          {props.dataMetric && props.dataMetric.childs && (
            <>
              <FormControl variant="outlined" className="formControl" fullWidth>
                <InputLabel ref={inputLabel}>SELECT CALCULATION</InputLabel>
                <Select
                  value={props.type}
                  input={<OutlinedInput labelWidth={labelWidth} />}
                  onChange={(e) => {
                    props.setType(e.target.value);
                  }}
                >
                  {props.dataMetric.childs.map((item, index) => {
                    return (
                      <MenuItem value={item.metric} key={index}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )}
        </div>
        <div className="data">
          <div className="date-picker-wrap">
            <DatePicker
              label="Start"
              future={true}
              format={"MM/DD/YYYY"}
              value={props.date[0]}
              onChange={(value) => {
                onChange(value, 0);
              }}
            />
          </div>
          <div className="date-picker-wrap">
            <DatePicker
              label="End"
              future={true}
              format={"MM/DD/YYYY"}
              value={props.date[1]}
              onChange={(value) => {
                onChange(value, 1);
              }}
            />
          </div>
        </div>
      </div>
      <div className="nav-menu select-group">
        <Dropdown className="btn-diagram">
          <Dropdown.Toggle>Add Data</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setAddSkinfold(true)}>
              Metrics
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setBodyFat(true)}>
              Goals
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <div className="btn-diagram">
          <Button variant="primary" onClick={() => props.setOutputMetric(1)}>
            Update Data
          </Button>
        </div>

        <div className="btn-diagram print-btn">
          <Link to={props.getPdf()} target="_blank">
            <Button variant="primary">Print</Button>
          </Link>
        </div>

        <Button
          className="btn-diagram protocol-btn"
          variant="primary"
          onClick={props.loadProtocol}
        >
          Protocol
        </Button>
      </div>
      <div className="canvass-wrap">
        <canvas ref={ref} id="kt_chart_order_statistics" />
      </div>
    </StyledDiagram>
  );
};

const StyledDiagram = styled.div`
  .canvass {
    &-wrap {
      height: 400px;
    }
  }

  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &.select-group {
      justify-content: flex-start;
    }

    .btn-diagram {
      margin-right: 15px;
      margin-bottom: 10px;
    }
  }

  .data {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin: 0 20px 0 0;
    }

    .MuiFormControl-marginNormal {
      margin: 0;
    }
  }

  .select {
    display: flex;
    align-items: center;

    &-wrap {
      height: 30px;
      display: flex;

      & > div {
        min-width: 300px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .nav-menu {
      .select,
      .formControl {
        width: 100%;
      }
    }

    .data {
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
      width: 100%;

      & > div {
        margin: 0;
      }
    }

    .nav-menu {
      gap: 10px;

      .btn-diagram {
        margin-right: auto;
      }

      .btn-diagram:last-child {
        margin-right: 0;
      }

      // .protocol-btn,
      // .print-btn {
      //   display: none;
      // }
      .date-picker-wrap,
      .input-mask-wrapper {
        width: 100%;
      }
    }
  }
`;
