import React from "react";
import { TableRow } from "./TableRow";
import { Checkbox } from "@material-ui/core";
import { tableHead } from "./tableHead";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import "./table.scss";

export const Table = ({
  data,
  check,
  sort,
  tableIndex,
  setTablesArray,
  status,
}) => {
  const checked = data?.length && check?.length === data?.length;

  const handleCheck = () => {
    if (data?.length) {
      if (!checked) {
        setTablesArray((prev) => {
          let arr = [...prev];
          arr[tableIndex].check = data.map((elem) => elem.id);
          return arr;
        });
      } else {
        setTablesArray((prev) => {
          let arr = [...prev];
          arr[tableIndex].check = [];
          return arr;
        });
      }
    }
  };

  const sortEdit = (item) => {
    if (item.sort) {
      setTablesArray((prev) => {
        let tablesArray = [...prev];
        tablesArray[tableIndex].sort = [
          item.value,
          `${sort[1] === "asc" ? "desc" : "asc"}`,
        ];

        switch (item.value) {
          case "title":
            tablesArray[tableIndex].events = tablesArray[
              tableIndex
            ].events.sort((a, b) => {
              let first = a.title.toLowerCase();
              let second = b.title.toLowerCase();

              if (first < second) {
                return sort[1] === "asc" ? -1 : 1;
              }

              if (first > second) {
                return sort[1] === "asc" ? 1 : -1;
              }

              return 0;
            });
            break;
          case "start_at":
            tablesArray[tableIndex].events = tablesArray[
              tableIndex
            ].events.sort((a, b) => {
              return sort[1] === "asc"
                ? new Date(a.start_at) - new Date(b.start_at)
                : new Date(b.start_at) - new Date(a.start_at);
            });
            break;
          case "end_at":
            tablesArray[tableIndex].events = tablesArray[
              tableIndex
            ].events.sort((a, b) => {
              return sort[1] === "asc"
                ? new Date(a.end_at) - new Date(b.end_at)
                : new Date(b.end_at) - new Date(a.end_at);
            });
            break;
          case "profiles":
            tablesArray[tableIndex].events = tablesArray[
              tableIndex
            ].events.sort((a, b) => {
              let first = a.profiles
                .map((elem) => `${elem.first_name} ${elem.last_name}`)
                .join(", ")
                .toLowerCase();
              let second = b.profiles
                .map((elem) => `${elem.first_name} ${elem.last_name}`)
                .join(", ")
                .toLowerCase();

              if (first < second) {
                return sort[1] === "asc" ? -1 : 1;
              }

              if (first > second) {
                return sort[1] === "asc" ? 1 : -1;
              }

              return 0;
            });
            break;
          case "users":
            tablesArray[tableIndex].events = tablesArray[
              tableIndex
            ].events.sort((a, b) => {
              let first = a.users
                .map((elem) => `${elem.first_name} ${elem.last_name}`)
                .join(", ")
                .toLowerCase();
              let second = b.users
                .map((elem) => `${elem.first_name} ${elem.last_name}`)
                .join(", ")
                .toLowerCase();

              if (first < second) {
                return sort[1] === "asc" ? -1 : 1;
              }

              if (first > second) {
                return sort[1] === "asc" ? 1 : -1;
              }

              return 0;
            });
            break;
          default:
            break;
        }

        return tablesArray;
      });
    }
  };

  return (
    <div className="calendar-agenda-table settingtable nowrap">
      <table
        className={`${
          status === "isDashboard" ? "calendar-agenda__table" : ""
        } `}
      >
        <thead>
          <tr>
            {status !== "isDashboard" && (
              <th>
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={handleCheck}
                />
              </th>
            )}
            {tableHead.map((item, index) => (
              <th
                key={index}
                onClick={() => sortEdit(item)}
                className={`${item.left ? "left-content" : ""}`}
                style={{ cursor: !item.sort && "default" }}
              >
                {item.label}
                {item.sort && (
                  <ArrowDown
                    className={`sort-arrow
                                            ${
                                              sort[0] === item.value
                                                ? " active"
                                                : ""
                                            }${
                      sort[0] === item.value && sort[1] === "desc"
                        ? " reverse"
                        : ""
                    }
                                            `}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <TableRow
              key={item.id}
              item={item}
              check={check}
              tableIndex={tableIndex}
              setTablesArray={setTablesArray}
              status={status}
            />
          ))}
        </tbody>
      </table>

      {!data?.length && (
        <div
          style={{
            margin: "20px auto",
            textAlign: "center",
          }}
        >
          No events are planned for this date
        </div>
      )}
    </div>
  );
};
