import React from "react";
import styled from "styled-components";

export default ({ data }) => {
  return (
    <StyledHPM className="settingtable">
      <thead>
        <tr>
          <th>METRIC</th>
          <th>CLASSIFICATION</th>
          <th>DATE</th>
          <th>DAYS</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.metric}</td>
              <td className={"theme-data"}>{item.classification || "--"}</td>
              <td>{item.date || "--"}</td>
              <td className={"theme-data"}>{item.days || "--"}</td>
            </tr>
          );
        })}
      </tbody>
    </StyledHPM>
  );
};

const StyledHPM = styled.table`
  @media screen and (max-width: 767px) {
    overflow: auto;
    width: 100%;

    thead th {
      position: sticky;
      top: 0;
      background-color: #f4f4f4;
      z-index: 2;
      padding: 8px 10px;
      font-size: 14px;
      height: 50px;
    }

    tbody td:first-child,
    thead th:first-child {
      position: sticky;
      left: 0;
    }
    tbody td:first-child {
      z-index: 10;
      background-color: #f9f9f9;
    }
    thead th:first-child {
      z-index: 11;
    }

    .th {
      height: 50px;
      font-weight: 500;
      cursor: pointer;
      padding: 8px 10px;
      font-size: 14px;
    }
  }
`;
