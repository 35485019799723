import React from "react";
import styled from "styled-components";

export default (props) => {
  return (
    <StyledRMO className="settingtable">
      <thead>
        <tr>
          <th>DATE</th>
          {props.data.map((item, index) => {
            return <th key={index}> {item.date}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{props.label1}</td>
          {props.data.map((item, index) => {
            return (
              <td className={"theme-data"} key={index}>
                {item.value || item.value === 0 ? item.value : "--"}
              </td>
            );
          })}
        </tr>
        {props.data[0] && props.data[0].classification && (
          <tr>
            <td>{props.label2} Classification</td>
            {props.data.map((item, index) => {
              return (
                <td key={index}>
                  {item.classification || item.classification === 0
                    ? item.classification
                    : "--"}
                </td>
              );
            })}
          </tr>
        )}
      </tbody>
    </StyledRMO>
  );
};

const StyledRMO = styled.table`
  @media screen and (max-width: 767px) {
    overflow: auto;
    width: 100%;

    thead th {
      position: sticky;
      top: 0;
      background-color: #f4f4f4;
      z-index: 2;
      padding: 8px 10px;
      font-size: 14px;
      height: 50px;
    }

    tbody td:first-child,
    thead th:first-child {
      position: sticky;
      left: 0;
    }
    tbody td:first-child {
      z-index: 10;
      background-color: #f9f9f9;
    }
    thead th:first-child {
      z-index: 11;
    }

    .th {
      height: 50px;
      font-weight: 500;
      cursor: pointer;
      padding: 8px 10px;
      font-size: 14px;
    }
  }
`;
