import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DataOutput from "../../../components/DataOutput";
import UserInfo from "../../../components/UserInfo";
import { Portlet } from "../../../partials/content/Portlet";
import { Redirect } from "react-router-dom";
import { Tab, Tabs } from "@material-ui/core";
import { Pagination } from "react-bootstrap";
// eslint-disable-next-line no-restricted-imports
import TextField from "@material-ui/core/TextField";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ListIcon from "@material-ui/icons/List";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  OutlinedInput,
} from "@material-ui/core";
import Loader from "../../../components/Loader";
import { getFiles } from "../../../crud/file.crud";
import { getProfileValid } from "../../../crud/profile.crud";
import Photo from "./Tabs/Photo";
import Video from "./Tabs/Video";
import File from "./Tabs/File";
import UploadModal from "./UploadModal";
import { useIsMount } from "../../../hooks/useIsMount";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../store/ducks/auth.duck";

export default (props) => {
  const dispatch = useDispatch();
  const firstRender = useIsMount();
  const id = props.match.params.id;
  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);
  const [tab, setTab] = useState(0);
  const [activePreview, setActivePreview] = useState(0);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [viewType, setViewType] = useState(0);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 10,
    to: 0,
    total: 0,
    sort: "test_date",
    dir: "desc",
    media_type: "images",
    search: "",
  });
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    getProfileValid(id).then((res) => {
      setRedirect(!res.data.is_valid);
      if (!res.data.is_valid) {
        dispatch(
          AlertState.actions.alert({
            text: "Please fill out the required fields below to continue",
            variant: false,
          })
        );
      }
    });
  }, [id]);

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel]);

  useEffect(() => {
    if (firstRender) return;

    setData([]);
    setMeta({
      ...meta,
      media_type: tab === 0 ? "images" : tab === 1 ? "videos" : "documents",
    });
  }, [tab]);

  useEffect(() => {
    if (firstRender) return;

    const handler = setTimeout(() => {
      setMeta({
        ...meta,
        search: search,
      });
    }, 1000);

    return () => clearTimeout(handler);
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [meta]);

  const fetchData = () => {
    setLoader(true);
    getFiles(id, meta)
      .then((res) => {
        setLoader(false);
        setData(res.data.data);
        if (
          JSON.stringify(meta) !== JSON.stringify({ ...meta, ...res.data.meta })
        )
          setMeta({ ...meta, ...res.data.meta });
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <FileStyled>
      <Loader visible={loader} />

      {redirect && <Redirect to={`/profile/${id}/info`} />}

      <div className="row flex-md-row-reverse" style={{ marginBottom: "20px" }}>
        <DataOutput id={id} />
        <UserInfo id={id} />
      </div>

      <Portlet className="portlet files">
        <div className="files__header">
          <UploadModal
            id={id}
            fetchData={fetchData}
            loader={loader}
            setLoader={setLoader}
            tab={tab}
          />
          <div className="files__header__search">
            <TextField
              label="Search"
              variant="outlined"
              style={{ width: "100%" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <div className="toolbarm">
              <div
                className={`toolbarm-elem ${viewType === 0 ? "active" : ""}`}
                onClick={() => setViewType(0)}
              >
                <ViewModuleIcon />
              </div>
              <div
                className={`toolbarm-elem ${viewType === 1 ? "active" : ""}`}
                onClick={() => setViewType(1)}
              >
                <ListIcon />
              </div>
            </div>
          </div>
        </div>

        <Tabs
          value={tab}
          onChange={(e, value) => setTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="Photos" />
          <Tab label="Videos" />
          <Tab label="Docs" />
        </Tabs>

        {data.length === 0 ? (
          <div
            style={{
              margin: "20px auto",
              textAlign: "center",
            }}
          >
            {search.length
              ? "No result found"
              : `You don't have any ${
                  tab === 0 ? "photos" : tab === 1 ? "videos" : "files"
                } yet`}
          </div>
        ) : (
          <>
            <div className="files__data">
              {tab === 0 ? (
                <Photo
                  viewType={viewType}
                  data={data}
                  meta={meta}
                  setMeta={setMeta}
                  loader={loader}
                  setLoader={setLoader}
                  id={id}
                  fetchData={fetchData}
                  activePreview={activePreview}
                  setActivePreview={setActivePreview}
                />
              ) : tab === 1 ? (
                <Video
                  viewType={viewType}
                  data={data}
                  meta={meta}
                  setMeta={setMeta}
                  loader={loader}
                  setLoader={setLoader}
                  id={id}
                  fetchData={fetchData}
                  activePreview={activePreview}
                  setActivePreview={setActivePreview}
                />
              ) : (
                <File
                  viewType={viewType}
                  data={data}
                  meta={meta}
                  setMeta={setMeta}
                  loader={loader}
                  setLoader={setLoader}
                  id={id}
                  fetchData={fetchData}
                  activePreview={activePreview}
                  setActivePreview={setActivePreview}
                />
              )}
            </div>

            <div className="pagination-wrap">
              <Pagination size="lg">
                <Pagination.First
                  onClick={() => setMeta({ ...meta, current_page: 1 })}
                  disabled={meta.current_page === 1}
                />
                <Pagination.Prev
                  onClick={() =>
                    setMeta({ ...meta, current_page: meta.current_page - 1 })
                  }
                  disabled={meta.current_page === 1}
                />

                {/* {renderPreviousPages()} */}
                <Pagination.Item active>{meta.current_page}</Pagination.Item>
                {/* {renderNextPages()} */}

                <Pagination.Next
                  onClick={() =>
                    setMeta({ ...meta, current_page: meta.current_page + 1 })
                  }
                  disabled={meta.current_page === meta.last_page}
                />
                <Pagination.Last
                  onClick={() =>
                    setMeta({ ...meta, current_page: meta.last_page })
                  }
                  disabled={meta.current_page === meta.last_page}
                />
              </Pagination>
              <div className="pagination-show">
                <FormControl variant="outlined" className="formControl">
                  <InputLabel ref={inputLabel}>Show Entries</InputLabel>
                  <Select
                    value={meta.per_page}
                    input={<OutlinedInput labelWidth={labelWidth} />}
                    onChange={(e) => {
                      setMeta({ ...meta, per_page: e.target.value });
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body1" gutterBottom>
                  {meta.current_page &&
                    `Showing
                                        ${(meta.current_page - 1) *
                                          meta.per_page +
                                          (meta.total ? 1 : 0)} - 
                                        ${
                                          meta.current_page * meta.per_page >
                                          meta.total
                                            ? meta.total
                                            : meta.current_page * meta.per_page
                                        }
                                    of ${meta.total}`}
                </Typography>
              </div>
            </div>
          </>
        )}
      </Portlet>
    </FileStyled>
  );
};

const FileStyled = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .files {
    padding: 25px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__search {
        display: flex;
        align-items: center;
      }
    }

    &__data {
    }
  }

  .toolbarm {
    display: flex;
    align-items: center;
    margin-left: 20px;

    &-elem {
      border-radius: 4px;
      padding: 3px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .MuiSvgIcon-root {
        height: 30px;
        width: 30px;
      }

      &.active {
        background: #e5e9f0;
      }
    }
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }

  @media screen and (max-width: 757px) {
    .settingtable {
      overflow: hidden !important;
    }
    .table-container {
      max-height: 700px;
      overflow: scroll;
    }

    .pagination-wrap {
      margin-top: 20px;
    }

    .pagination {
      margin-bottom: 0;
      margin: 0 auto 20px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .pagination {
      li {
        font-size: 14px;
      }
    }
    .pagination-lg .page-link {
      padding: 1rem 2rem;
      font-size: 18px;
      display: flex;
      justify-content: center;
    }
    .pagination-show {
      margin-bottom: 10px;
      margin-right: auto;
      .MuiSelect-selectMenu.MuiOutlinedInput-input {
        padding: 10px 10px 10px 75px !important;
      }
      .MuiSelect-icon {
        left: 5px;
      }
      .formControl {
        width: 100px;
        order: 1;
        margin-left: 10px;
      }
    }
  }
`;
